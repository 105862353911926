<template>
  <div>
    <div class="feat-container">
      <div class="feature-carousel-container">
        <div class="feature-slider-header">
          <div class="feature-slider-head">
            <div class="feature-slider-head-btns">
              <div
                :id="`feature-slider-controls`"
                class="feature-slider-controls"
              >
                <!-- previous-arrow -->
                <button
                  type="button"
                  class="feature-prev"
                  @click="sliderPrevClicked()"
                >
                  <img
                    src="@/assets/icons/less-than-filled-50.png"
                    width="30px"
                    alt="prev"
                  />
                </button>
                <!-- next-arrow -->
                <button
                  type="button"
                  class="feature-forward"
                  @click="sliderNextClicked()"
                >
                  <img
                    src="@/assets/icons/more-than-filled-50.png"
                    width="30px"
                    alt="for"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="featured-carousel" id="featured-carousel">
          <div class="feat-card" v-for="(item, key) in contents" :key="key">
            <div class="feat-card-container">
              <div
                class="feat-card-box-contain"
                :class="[
                  localDisplayLang == 'ara' ? 'feat-card-box-contain-ara' : '',
                ]"
                id="card-${key}"
                :item="item"
                :objectid="item.objectid"
                :style="[
                  { 'background-image': `url(${getPoster(item)})` },
                  { height: `${cardHeight}px` },
                ]"
                @click="playContentWrapper(item, $event)"
              >
                <div
                  :class="[
                    localDisplayLang === 'ara'
                      ? 'content-tag-right'
                      : 'content-tag',
                  ]"
                  v-if="isObjectTag(item)"
                >
                  <div class="content-tag-item">
                    <p>{{ pickTagForContent(item) }}</p>
                  </div>
                </div>

                <div
                  class="card-text"
                  id="card-text"
                  :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
                >
                  <!-- <div class="card-title">
                    <p>{{ item.title.toUpperCase() }}</p>
                  </div>-->

                  <div class="tags">
                    <p>{{ $t(item.category) }}</p>
                    <p>{{ $t(item.genre) }}</p>
                    <p
                      v-if="
                        item.hasOwnProperty('contentlanguage') &&
                        item.contentlanguage[0]
                      "
                    >
                      {{ $t(item.contentlanguage[0]) }}
                    </p>
                    <p>{{ item.productionyear }}</p>
                    <p dir="ltr" class="pg-rating" v-if="item.pgrating">
                      {{ item.pgrating }}
                    </p>
                  </div>
                  <div class="duration-views" v-if="item.category === 'MOVIE'">
                    <div
                      class="duration-views-eng"
                      v-if="localDisplayLang !== 'ara'"
                      dir="ltr"
                    >
                      <img
                        src="@/assets/icons/Duration.svg"
                        alt="items-count"
                      />
                      <p class="duration-views-eng-number">
                        {{ calcTimeFormat(item.duration) }}
                      </p>
                    </div>
                    <div class="duration-views-ara" dir="ltr" v-else>
                      <img
                        src="@/assets/icons/Duration.svg"
                        alt="items-count"
                      />
                      <p class="duration-views-eng-number">
                        {{ calcTimeFormat(item.duration) }}
                      </p>
                    </div>
                    <!-- <p>{{ item.views }}</p> -->
                  </div>
                  <div class="duration-views" v-if="item.category === 'TVSHOW'">
                    <img
                      src="@/assets/icons/season-light.svg"
                      alt="items-count"
                    />
                    <p class="duration-views-number">
                      {{ item.seasoncount }}
                      {{
                        item.seasoncount === 1 ? $t("Season") : $t("Seasons")
                      }}
                    </p>
                    <!-- <p>{{ item.views }}</p> -->
                  </div>
                  <!-- <div class="card-description" v-if="item.shortdescription">
                    <p v-if="item.shortdescription.length <= 100">
                      {{ item.shortdescription }}
                    </p>
                    <p v-else>
                      {{ item.shortdescription.substring(0, 100) }}...
                    </p>
                  </div> -->

                  <div
                    class="content-action-btns"
                    v-if="item.hasOwnProperty('objectid')"
                  >
                    <div class="activity-buttons">
                      <LikeBtn
                        :content="item"
                        :width="'25px'"
                        :height="'25px'"
                        class="hover-change"
                      ></LikeBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("like") }}</span>
                      </div>
                    </div>
                    <div class="activity-buttons">
                      <DislikeBtn
                        :content="item"
                        :width="'25px'"
                        :height="'25px'"
                        class="hover-change"
                      ></DislikeBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("dislike") }}</span>
                      </div>
                    </div>
                    <div class="activity-buttons">
                      <detailIcon
                        :content="item"
                        :width="'25px'"
                        :height="'25px'"
                        class="hover-change"
                      ></detailIcon>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("share") }}</span>
                      </div>
                    </div>
                    <div class="activity-buttons">
                      <WatchListBtn
                        :content="item"
                        :width="'25px'"
                        :height="'25px'"
                        class="hover-change"
                      ></WatchListBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("My List") }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="play-btn-container">
                  <!-- <img
                    class="play-btn"
                    src="@/assets/icons/Ic_Play1.svg"
                    alt
                    @click="playContent(item)"
                  />-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="feat-nav-container" id="feat-nav-container">
          <button
            class="nav-button"
            type="button"
            v-for="(item, key) in contents"
            :key="key"
            @click="navBtnClicked(key)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _projectName } from "@/provider-config.js";
export default {
  name: "FeaturedCarousel",
  props: {
    featuredScreen: {
      type: Object,
    },
    contents: {
      type: Array,
    },
  },
  data() {
    return {
      // enableFeatured: false,
      cardHeight: 430,
      slider: null,
      triangleColor: null,
      toolTipColor: null,
      localDisplayLang: null,
      counter: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getToken",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getRtl",
      "getDisplayLanguageCode",
      "appConfig",
    ]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.actChangeBackgroundCarousel();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.actChangeBackgroundCarousel();
    });
    // tooltip color
    this.toolTipColor =
      _projectName === "NET5" ||
      _projectName === "VLIVE" ||
      _projectName === "Noor Play"
        ? "tooltip-color"
        : _projectName === "NAMMAFLIX"
        ? "tooltip-color-white"
        : "";

    // tooltip triangle Color change
    this.triangleColor =
      _projectName === "NET5" ||
      _projectName === "VLIVE" ||
      _projectName === "Noor Play"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
  },
  mounted() {
    this.renderCarousel();
  },
  methods: {
    ...mapActions(["getScreenContents"]),
    actChangeBackgroundCarousel() {
      let deg = this.localDisplayLang == "eng" ? "90deg" : "-90deg";
      document.documentElement.style.setProperty(
        "--backgroud-carousel-color-rotation",
        deg
      );
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    playContentWrapper(item, e) {
      console.log("CLICK ON FEATURED");
      localStorage.setItem("playback_source", "Featured");
      this.playContent(item, e);
    },
    carouseldata(e) {
      console.log("From carousel data");
      let objectid =
        e.srcElement &&
        e.srcElement.attributes.objectid &&
        e.srcElement.attributes.objectid.value
          ? e.srcElement.attributes.objectid.value
          : "";

      let item = this.contents.filter((data) => {
        if (data.objectid == objectid) return data;
      });
      this.playContent(item[0], null);
    },
    //fetch featured carousel card height.
    fetchCardHeight() {
      let bodyWidth = document.body.offsetWidth - 600;
      let cardHeight = Math.round(bodyWidth / (16 / 9));
      if (cardHeight) {
        this.cardHeight = cardHeight + 80;
        if (this.isTablet()) {
          this.cardHeight += 50;
        }
      }
    },

    //fetch poster for featured content.
    getPoster(content) {
      // console.log("content", content);
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (
              (element.quality === "HD" || element.quality === "SD") &&
              element.postertype === "LANDSCAPE"
            );
          });
          // console.log("getPoster", index);
          if (index > -1) {
            return screen.width > 768
              ? content.poster[index].filelist[1].filename
              : content.poster[index].filelist[1].filename;
          }
        } else {
          return "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/ll2ufl37xpr_LANDSCAPE_LOW.jpg";
        }
      } else {
        if (content.poster) {
          return this.localDisplayLang === "ara"
            ? content.poster.LANDSCAPE.ar
            : content.poster.LANDSCAPE.en;
        }
      }
    },

    //play item from featured carousel.
    playContent(item, e) {
      if (e && (e.target.nodeName == "svg" || e.target.nodeName == "path")) {
        console.log("play content aborted : ", item);
        return;
      }
      if (item.hasOwnProperty("deeplink") && item.deeplink) {
        let id = item.deeplink.split("/")[3];
        if (id == "plan") {
          // if (!this.subscriberid) {
          //   //open lookup popup.
          //   let payload = {
          //     state: true,
          //     formType: "lookup",
          //     previousAction: { action: "deeplink", data: item },
          //   };
          //   eventBus.$emit("authPopup", payload);
          // } else {
          console.log("planid ------??>>", item.deeplink);
          let planid = item.deeplink.split("/")[4];
          let coupon = "";

          if (item.deeplink.split("/")[5]) {
            coupon = item.deeplink.split("/")[5];
          }

          const routeParams = {
            planId: planid,
          };

          if (coupon) {
            routeParams.coupon = coupon;
          }

          this.$router.push({
            name: "planandcoupondeeplink",
            params: routeParams,
          });
          // }
        } else {
          this.$router.push({ name: "screen", params: { section: `${id}` } });
        }
      } else if (item.hasOwnProperty("objectid")) {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          if (item.hasOwnProperty("objectid")) {
            // let payload = {
            //   content: item,
            //   state: true,
            // };
            // eventBus.$emit("showContentDetail", payload);
            if (item.category == "TVSHOW") {
              // let title;
              // if (this.localDisplayLang == "eng") {
              //   title = item.title.replace(/\s/g, "-");
              // } else {
              //   title = "title";
              // }
              let title = item.defaulttitle.replace(/\s/g, "-");
              this.$router.push({
                name: "detailPage",
                params: {
                  contentId: item.objectid.toLowerCase(),
                  mediaTitle: title.toLowerCase(),
                  lang: currentLanguage,
                },
              });
            } else {
              // let title;
              // if (this.localDisplayLang == "eng") {
              //   title = item.title.replace(/\s/g, "-");
              // } else {
              //   title = "title";
              // }
              let title = item.defaulttitle.replace(/\s/g, "-");
              this.$router.push({
                name: "detailPageMovie",
                params: {
                  mediaTitle: title,
                  contentId: item.objectid,
                  lang: currentLanguage,
                },
              });
            }
          }
        } else {
          //this.$router.push({ name: "detail", params: { contentid: item.objectid } });
        }
      }
    },

    //construct carousel for featured contents.
    renderCarousel() {
      if (document.getElementById("featured-carousel")) {
        this.$nextTick(() => {
          if (this.slider) return;
          if (!document.getElementById("featured-carousel")) return;

          let tanyPros = {
            startIndex: 0,
            autoplayDirection: "forward",
          };

          if (this.localDisplayLang == "ara") {
            tanyPros = {
              startIndex: this.contents.length - 1,
              autoplayDirection: "backward",
            };
          }
          this.slider = tns({
            container: "#featured-carousel",
            navContainer: "#feat-nav-container",
            controlsContainer: "#feature-slider-controls",
            slideBy: 1,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            autoHeight: true,
            freezable: false,
            loop: true,
            rewind: false,
            // controls: false,
            nav: true,
            speed: 400,
            items: 1,
            startIndex: tanyPros.startIndex,
            autoplayDirection: tanyPros.autoplayDirection,
            gutter: 35,
            responsive: {
              // 1350: {
              //   items: 1,
              //   gutter: 50,
              //   edgePadding: 300
              // },
              1200: {
                items: 1,
                gutter: 25,
                edgePadding: 320,
              },
              1024: {
                items: 1,
                gutter: 25,
                edgePadding: 150,
              },
              768: {
                items: 1,
                gutter: 25,
                edgePadding: 25,
              },
              576: {
                items: 1,
                gutter: 25,
                edgePadding: 25,
              },
            },
          });

          // const info = this.slider.getInfo();
          // console.log("infor", info)
          // this.slider.goTo('last');
        });
      }
    },
    //button navigator for featured carousel.
    navBtnClicked(key) {
      if (this.slider) {
        this.slider.play();
      }
    },
    sliderPrevClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.displayIndex - 1);
      setTimeout(() => {
        this.slider.play();
      }, 100);
    },
    sliderNextClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.displayIndex - 1);
      setTimeout(() => {
        this.slider.play();
      }, 100);
    },
  },
  components: {
    // FeaturedCarouselPlaceholder: () =>
    //   import(
    //     /* webpackChunkName: "featplaceholder" */ "@/components/placeholders/FeaturedCarouselPlaceholder.vue"
    //   ),
    LikeBtn: () =>
      import(
        /* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"
      ),
    DislikeBtn: () =>
      import(
        /* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"
      ),
    WatchListBtn: () =>
      import(
        /* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"
      ),
    detailShare: () =>
      import(
        /* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"
      ),
    detailIcon: () =>
      import(
        /* webpackChunkName: "detailicon" */ "@/components/SvgImages/detailIcon.vue"
      ),
    imageItem: () =>
      import(
        /* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"
      ),
  },
  mixins: [Utility],
};
</script>
<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";

:root {
  --backgroud-carousel-color-rotation: "90deg";
}
.feat-container {
  position: relative;
  padding: 0.6rem 0;
  .feature-carousel-container {
    .feature-slider-head {
      .feature-slider-head-btns {
        .feature-slider-controls {
          position: absolute;
          width: 99%;
          top: 36%;
          // display: flex;
          align-items: center;
          justify-content: space-between;
          display: none;
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            z-index: 1;
            transition: 0.3s;
            img {
              height: 50px;
              width: 50px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
            &:hover {
              transform: scale(1.3);
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .feat-card {
      .feat-card-container {
        // height: 500px;
        // width: 888px;
        .feat-card-box-contain {
          background-position: left;
          background-repeat: no-repeat;
          cursor: pointer;
          background-size: cover;
          border-radius: 10px;
          position: relative;
          height: 100%;
          width: 100%;

          &-ara {
            background-position: right;
          }

          .content-tag {
            position: absolute;
            top: 2%;
            left: 0;
            .content-tag-item {
              color: #fff;
              background: $btn-clr-new;
              font-family: $font-regular;
              padding: 5px 10px;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              p {
                font-size: 0.7rem;
              }
            }
          }
          .content-tag-right {
            position: absolute;
            top: 2%;
            right: 0;
            .content-tag-item {
              color: #fff;
              background: $btn-clr-new;
              font-family: $font-regular;
              padding: 5px 10px;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              p {
                font-size: 0.7rem;
              }
            }
          }
          .card-text {
            font-family: $font-regular;
            position: absolute;
            width: 100%;
            padding: 0.6rem 2.5rem 0.8rem 2.5rem;
            bottom: 0%;
            opacity: 0;
            color: white;
            z-index: 1;
            background: linear-gradient(
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.04) 30%,
              rgba(0, 0, 0, 0.93) 100%
            );
            // background: transparent
            //   linear-gradient(90deg, #272626 100%, #00000053 50%, #64646400 100%)
            //   0% 0% no-repeat padding-box;
            border-radius: 0px;
            .card-title {
              p {
                color: #ffffff;
                font-family: $font-regular;
                font-size: 2rem;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.5rem;
                text-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.77);
              }
            }
            .duration-views {
              display: flex;
              // img {
              //   margin-right: 7px;
              // }
              &-number {
                margin: 0 5px;
                opacity: 1;
                color: #848486;
                font-family: $font-regular;
                font-size: 0.9rem;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.5rem;
              }
              &-eng {
                display: flex;
                img {
                  margin-right: 7px;
                }
                &-number {
                  opacity: 1;
                  color: #ffffff;
                  font-family: $font-regular;
                  font-size: 0.8rem;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 22px;
                }
              }
              &-ara {
                display: flex;
                img {
                  margin-right: 7px;
                }
                &-number {
                  opacity: 1;
                  color: #ffffff;
                  font-family: $font-regular;
                  font-size: 0.8rem;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 1.5rem;
                }
              }
            }
            .content-action-btns {
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;
              width: 150px;
              .activity-buttons {
                position: relative;
                cursor: pointer;

                &:hover {
                  .triangle {
                    display: block;
                  }
                  .tooltip {
                    display: block;
                  }
                  margin-top: -3px;
                  transition: 0.3s;
                }
                .hover-change {
                  // opacity: 0.7;
                  &:hover {
                    opacity: 1;
                  }
                }
                .triangle {
                  display: none;
                  position: absolute;
                  top: -4px;
                  left: 10px;
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                }
                .triangle-color {
                  border-top: 5px solid $btn-clr;
                }
                .triangle-color-white {
                  border-top: 5px solid rgba(255, 255, 255, 0.6);
                }
                .tooltip {
                  display: none;
                  position: absolute;
                  top: -33px;
                  left: -5px;
                  width: max-content;
                  padding: 5px 10px;
                  border-radius: 5px 10px;
                  background: rgba(0, 0, 0, 0.9);
                  span {
                    font-size: 14px;
                    font-family: $font-regular;
                    opacity: 0.8;
                  }
                }
                .tooltip-color {
                  border: 1px solid $btn-clr;
                  span {
                    color: $btn-clr;
                  }
                }
                .tooltip-color-white {
                  border: 1px solid rgba(255, 255, 255, 0.6);
                  span {
                    color: $clr-light-gd2;
                  }
                }
              }
            }
            // .duration-views {
            //   display: flex;
            //   margin-top: 1%;
            //   p {
            //     padding-right: 1.5em;
            //     font-weight: bold;
            //   }
            // }
            .tags {
              display: flex;
              align-items: center;
              margin: 5px 0;
              p {
                color: #ffffff;
                font-family: $font-regular;
                font-size: 0.9rem;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.5rem;
                text-align: right;
                margin-right: 10px;
              }
              .pg-rating {
                padding: 0.2rem 0.5rem;
                border: 2px solid #ffffff;
                line-height: 12px;
              }
            }
            .card-description {
              width: 60%;
              opacity: 0.5;
              color: #ffffff;
              font-family: $font-regular;
              font-size: 0.9rem;
              letter-spacing: 0;
              line-height: 1.5rem;
            }
          }
          .play-btn-container {
            position: absolute;
            top: -10%;
            bottom: 0%;
            left: 0%;
            right: 0%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            cursor: pointer;
            .play-btn {
              // width: 85px;
              cursor: pointer;
              opacity: 0.8;
              transition: 0.3s;
              &:hover {
                opacity: 1;
              }
            }
          }
          &:hover {
            .card-text {
              opacity: 1;
              transition: all 0.4s ease-in-out;
            }
            .play-btn-container {
              opacity: 1;
              transition: all 0.4s ease-in-out;
            }
          }
        }
      }
    }
    .feat-nav-container {
      text-align: center;
      padding: 10px 0;

      .nav-button {
        border: 2px solid gray;
        width: 11px;
        height: 11px;
        border-radius: 15px;
        margin: 5px;
        cursor: pointer;
        background-color: gray;
        &.tns-nav-active {
          background: $btn-clr-new;
          border: 2px solid $btn-clr;
          width: 20px;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &:hover {
      .feature-slider-head {
        .feature-slider-head-btns {
          .feature-slider-controls {
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .feat-container {
    padding: 0.6rem;
    .feature-carousel-container {
      .feature-slider-head {
        .feature-slider-head-btns {
          .feature-slider-controls {
            display: none !important;
          }
        }
      }
    }
    #featured-carousel-mw {
      height: 215px !important;
    }
    .feat-card-box-contain {
      height: 215px !important;
      // width: 355px !important;
      .content-tag,
      .content-tag-right {
        .content-tag-item {
          font-size: 0.7rem;
        }
      }
      .card-text {
        display: none;
      }
      .play-btn-container {
        .play-btn {
          width: 60px;
        }
      }
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .feat-container {
    // padding: 0.6rem;
    .feature-carousel-container {
      .feature-slider-head {
        .feature-slider-head-btns {
          .feature-slider-controls {
            display: none;
          }
        }
      }
    }
    #featured-carousel-mw {
      height: 350px !important;
    }
    .feat-card-box-contain {
      height: 350px !important;
      // height: 410px !important;
      // width: 728px !important;
      .card-text {
        display: none;
      }
      .play-btn-container {
        .play-btn {
          width: 60px;
        }
      }
    }
  }
}

@media only screen and (min-width: 370px) and (max-width: 575px) {
  .feat-container {
    // padding: 0.6rem;
    .feature-carousel-container {
      .feature-slider-head {
        .feature-slider-head-btns {
          .feature-slider-controls {
            display: none;
          }
        }
      }
    }
    #featured-carousel-mw {
      height: 200px !important;
    }
    .feat-card-box-contain {
      height: 200px !important;
      // height: 410px !important;
      // width: 728px !important;
      .card-text {
        display: none;
      }
      .play-btn-container {
        .play-btn {
          width: 40px;
        }
      }
    }
  }
}

// @media only screen and (min-width: 769px) and (max-width: 991px) {
//   .feat-container {
//     // padding: 0.6rem;
//     .feature-carousel-container {
//       .feature-slider-head {
//         .feature-slider-head-btns {
//           .feature-slider-controls {
//             display: none;
//           }
//         }
//       }
//     }
//     #featured-carousel-mw {
//       height: 410px !important;
//     }
//     .feat-card-box-contain {
//       height: 410px !important;
//       // height: 410px !important;
//       // width: 728px !important;
//       .card-text {
//         display: none;
//       }
//       .play-btn-container {
//         .play-btn {
//           width: 65px;
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 992px) {
//   .feat-container {
//     // padding: 0.6rem;
//     // .feature-carousel-container {
//     //   .feature-slider-head {
//     //     .feature-slider-head-btns {
//     //       .feature-slider-controls {
//     //         // display: flex;
//     //       }
//     //     }
//     //   }
//     // }
//     #featured-carousel-mw {
//       height: 430px !important;
//     }
//     .feat-card-box-contain {
//       height: 430px !important;
//       // height: 410px !important;
//       // width: 728px !important;
//     }
//     .play-btn-container {
//       .play-btn {
//         width: 70px;
//       }
//     }
//   }
// }

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   .feat-container {
//     // padding: 0.6rem;
//     // .feature-carousel-container {
//     //   .feature-slider-head {
//     //     .feature-slider-head-btns {
//     //       .feature-slider-controls {
//     //         // display: flex;
//     //       }
//     //     }
//     //   }
//     // }
//     #featured-carousel-mw {
//       height: 430px !important;
//     }
//     .feat-card-box-contain {
//       height: 430px !important;
//       // height: 410px !important;
//       // width: 728px !important;
//     }
//     .play-btn-container {
//       .play-btn {
//         width: 85px;
//       }
//     }
//   }
// }
@media only screen and (min-device-width: 1068px) and (max-device-width: 1100px) {
  .feat-card-box-contain {
    height: 390px !important;
  }
}
@media only screen and (min-device-width: 1101px) and (max-device-width: 1199px) {
  .feat-card-box-contain {
    height: 433px !important;
  }
}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1299px) {
  .feat-card-box-contain {
    height: 343px !important;
  }
}
@media only screen and (min-device-width: 1300px) and (max-device-width: 1399px) {
  .feat-card-box-contain {
    height: 383px !important;
  }
}
@media only screen and (min-device-width: 1400px) and (max-device-width: 1499px) {
  .feat-card-box-contain {
    height: 433px !important;
  }
}
@media only screen and (min-device-width: 1500px) and (max-device-width: 1550px) {
  .feat-card-box-contain {
    height: 463px !important;
  }
}
@media only screen and (min-device-width: 1551px) and (max-device-width: 1920px) {
  #featured-carousel-mw {
    height: 550px !important;
  }
  .feat-card-box-contain {
    height: 550px !important;
    //heiight: 80px !important;
    //width: 95% !important;
  }
  .play-btn-container {
    .play-btn {
      width: 95px;
    }
  }
}

// .feat-card {
//   filter: blur(5px);
// }
// .tns-slide-active {
//   &:nth-child(1) {
//     filter: none !important;
//   }
// }

// .card-blur {
//   filter: blur(6px);
// }
</style>
